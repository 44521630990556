//@ts-ignore
__webpack_public_path__ = window.reactBasePath ?? __webpack_public_path__;

if (document.getElementById('react-app')) {
  import('./entry');
} else {
  // import('./driver/entry-driver');
}

export {};
